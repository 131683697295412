import React, { useState, useEffect } from 'react';
import axios from 'axios';


import "bootstrap/dist/css/bootstrap.min.css";


function Lista() {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('http://10.10.10.14:8080//httpsspringboot/nfe/portal/27062024/27072024/0/0/0/0')
            .then(response => setData(response.data));
    }, []);

    return (
        <div>

            <table className='table'>
                <thead>
                    <tr align="center">
                        <th>NF</th>
                        <th>DATA EMISSAO</th>
                        <th>RAZAO SOCIAL</th>
                        <th>STATUS</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((output, index) =>
                        <tr key={index}>
                            <td align="center">{output.nNF}</td>
                            <td align="center">{output.dta_emissao}</td>
                            <td align="left">{output.razao_social}</td>
                            <td align="center">{output.status}</td>
                        </tr>
                    )}
                </tbody>
            </table>

        </div>
    );
}

export default Lista;