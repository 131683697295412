import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  ListItem,
  Menu,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";

import { NavDropdown } from "react-bootstrap";
import { DropdownSubmenu, MenuItem, NavDropdownMenu } from "react-bootstrap-submenu";
import logo from '../pages/logo.png';

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",    
  },
  link: {
    textDecoration: "none",
    color: "#BEBEBE",
    fontSize: "15px",
    marginLeft: theme.spacing(5),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "white",
      borderBottom: "1px solid white",
    },
  },
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar position="static" style={{ backgroundColor: "#343a40" }}>
      <CssBaseline />
      <Toolbar>
        <Typography variant="h5" className={classes.logo}>
        <img src={logo} style={{width:180}} />
        </Typography>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <Link to="/" className={classes.link}>
              Produtos
            </Link>
            <Link to="/about" className={classes.link}>
              Serviços
            </Link>
            <Link to="/contact" className={classes.link}>
              Caso de Sucesso
            </Link>
            <Link to="/faq" className={classes.link}>
              Sobre
            </Link>
            <Link to="/faq" className={classes.link}>
              Contato             
            </Link>   
            <Link to="/faq" className={classes.link}>
              Utilitários             
            </Link>    
            <Link to="/faq" className={classes.link}>
              Conhecimento             
            </Link>          
          </div>          
        )}
      </Toolbar>

    </AppBar>
  );
}
export default Navbar;
